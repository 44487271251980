import * as Y from "yjs";
import { YjsEditor, withYjs } from "@slate-yjs/core";
import { IDBInstace } from "@surge-global-engineering/y-indexeddb";
import { createPlateEditor, PlateEditor } from "@udecode/plate";
import { isElement } from "lodash";
import { BaseEditor, NodeEntry } from "slate";
import { MySceneElement, MyOrnamentalBreakElement } from "../../components/Plate/config/typescript";
import { removeNode } from "../slate";
import { syncDocWithRemote } from "../y";

/**
 * Return a headless editor instance with children of a given chapter id
 * 
 * editor.connect needs to be called on the returned editor instance to load the 
 * editor with the children from the sharedType and to listen and apply operations
 * performed on the editor to the sharedType / ydoc
 * @param {string} chapterId
 * @returns {YjsEditor} editor instance
 */
export const initializeHeadlessEditor = async (
  chapterId: string,
  ydoc: Y.Doc
): Promise<YjsEditor> => {
  /** initialize idb connection and sync updates from idb to ydoc */
  const idbInstace = new IDBInstace(chapterId);
  await idbInstace.initializeConnection();
  await idbInstace.syncUpdatesFromDBToDoc(ydoc);
  /** sync document with remote */
  await syncDocWithRemote(chapterId, ydoc);
  /** initialize the editor */
  const sharedType = ydoc.get("content", Y.XmlText) as Y.XmlText;
  const yjsEditor = withYjs(createPlateEditor() as BaseEditor, sharedType);
  return yjsEditor;
};

/**
 * Removes the scene and ornamental break nodes from the src chapter when 
 * drag and drop scenes between two chapters
 */
export const removeSrcSceneAndObNodes = (
  srcYJSeditor: PlateEditor,
  srcSceneEntry: NodeEntry<MySceneElement>,
  srcObEntry: NodeEntry<MyOrnamentalBreakElement>,
): void => {
  removeNode(srcYJSeditor, {
    match: (node) =>
      isElement(node) &&
      (node as MyOrnamentalBreakElement).id === srcObEntry[0].id,
  });
  removeNode(srcYJSeditor, {
    match: (node) =>
      isElement(node) &&
      (node as MySceneElement).id === srcSceneEntry[0].id,
  });
};
