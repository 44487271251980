import {
  isElement,
  removeNodes,
  PlateEditor,
  TNode,
  TDescendant,
  insertNodes,
  setNodes,
  moveNodes,
  MoveNodesOptions,
  SetNodesOptions,
  TElement,
  TNodeProps,
  TPath,
  getNode as plateGetNode,
  findNode,
  TEditor,
  RemoveNodesOptions
} from "@udecode/plate";
import {
  BaseEditor,
  Editor,
  EditorNodesOptions,
  Element,
  NodeEntry,
  Node
} from "slate";

export const getNode = <T extends Node>(
  editor: PlateEditor,
  nodeOptions: EditorNodesOptions<T>
): NodeEntry<T> | undefined => {
  const matchingNodes: NodeEntry<T>[] = [];
  for (const entry of Editor.nodes<T>(editor as BaseEditor, {
    ...nodeOptions,
    at: nodeOptions.at || [],
  })) {
    matchingNodes.push(entry);
  }
  return matchingNodes[0];
};

export const moveNode = (
  editor: PlateEditor,
  options: MoveNodesOptions
): void => {
  return moveNodes(editor, options);
};

export const removeCurrentNode = (
  editor: PlateEditor,
  node: NodeEntry<Element>
): void => {
  removeNodes(editor, {
    at: node[1],
  });
};

export const removeNode = (
  editor: PlateEditor,
  options: RemoveNodesOptions
): void => {
  removeNodes(editor, {at:[], ...options});
};

export const updateNode = <T extends TElement>(
  editor: PlateEditor,
  newProperties: Partial<TNodeProps<T>>,
  options: SetNodesOptions
): void => {
  setNodes<T>(editor, newProperties, options);
};

export const injectChildrenIntoNode = <T extends TNode>(
  editor: PlateEditor,
  parent: NodeEntry<T>,
  children: TDescendant[],
  offset = 0
): void => {
  insertNodes(editor, children, {
    at: { path: parent[1], offset },
  });
};

/**
 * Get the node before and after the source node of a given type. Matching nodes could 
 * be siblings, ancestors, or descendants before and after the position of the source node.
 * @param editor plate editor instance
 * @param node source node
 * @param matchType type of node to find before and after the source node
 * @returns matching before and after node entries if any
 */
export const getMatchingNodesBeforeAndAfter = <T extends TNode, S extends TNode>(
  editor: PlateEditor,
  node: NodeEntry<T>,
  matchType: NodeEntry<S>[0]["type"]
): {
  previous: NodeEntry<S> | undefined;
  next: NodeEntry<S> | undefined;
} => {
  const prevSibling = Editor.previous<S>(editor as BaseEditor, {
    at: node[1],
    match: (n) => isElement(n) && n.type === matchType,
  });
  const nextSibling = Editor.next<S>(editor as BaseEditor, {
    at: node[1],
    match: (n) => isElement(n) && n.type === matchType,
  });
  return {
    previous: prevSibling,
    next: nextSibling,
  };
};

/**
 * Get the immediate previous and next sibling node entries (nodes of the same level as the source) 
 * of the source node
 * @param editor plate editor instance
 * @param path path of the source node
 * @returns immediate previous and next sibling node entries if any
 */
export const getAdjoiningSiblings = (
  editor: PlateEditor,
  path: TPath
): {
  previous: NodeEntry | undefined;
  next: NodeEntry | undefined;
} => {
  const currentIndex = path[path.length - 1];
  let previousNodeEntry: NodeEntry | undefined;
  let nextNodeEntry: NodeEntry | undefined;
  if (currentIndex > 0) {
    const previousSiblingIndex = currentIndex - 1;
    const previousSiblingPath = path
      .slice(0, -1)
      .concat([previousSiblingIndex]) as TPath;
    const previousSiblingNode = plateGetNode(editor, previousSiblingPath);
    if (previousSiblingNode)
      previousNodeEntry = [previousSiblingNode, previousSiblingPath];
  }
  const nextSiblingIndex = currentIndex + 1;
  const nextSiblingPath = path.slice(0, -1).concat([nextSiblingIndex]) as TPath;
  const nextSiblingNode = plateGetNode(editor, nextSiblingPath);
  if (nextSiblingNode) nextNodeEntry = [nextSiblingNode, nextSiblingPath];
  return {
    previous: previousNodeEntry,
    next: nextNodeEntry,
  };
};

// TODO: see if we can do the same using Editor.nodes()
export const findAllNodesInDocByType = <T extends TElement>(
  editor: BaseEditor,
  nodeType: T["type"]
): NodeEntry<T>[] => {
  const nodeEntries: NodeEntry<T>[] = [];
  const getNextNodeEntry = (editor: BaseEditor, start: TPath, firstIteration = false): void => {
    let nextNode;
    if(firstIteration) {
      nextNode = findNode(editor as TEditor, {
        at: start,
        match: (node) => isElement(node) && node.type === nodeType,
      });

      if(!nextNode) {
        nextNode = Editor.next<T>(editor, {
        at: start,
        match: (node) => isElement(node) && node.type === nodeType,
      });
      }
    } else {
      nextNode = Editor.next<T>(editor, {
        at: start,
        match: (node) => isElement(node) && node.type === nodeType,
      });
    }
    if (nextNode) {
      nodeEntries.push(nextNode);
      getNextNodeEntry(editor, nextNode[1], false);
    } else {
      return;
    }
  };
  const firstNodeInDoc = Editor.first(editor, []);
  getNextNodeEntry(editor, firstNodeInDoc[1], true);
  return nodeEntries;
};