import { Editor, Path, Text, Transforms, BaseEditor, Descendant, Node } from "slate";
import { PlateEditor, TText } from "@udecode/plate";
import { toJS } from "mobx";

import { DecoratedRange } from "../../../types/book";
import { sanitizeRegexCharacters } from "../../../utils/helper";
import { TrackChangesProperties } from "../plugins/track-changes";
import { db } from "../../../db/bookDb";
import { getPlateChapterBodyForChapter } from "../../../utils/y";
import bookStore from "../../../store/Book";

export const MIN_SEARCH_WORD_LENGTH = 1;

/**
 * Return a list of ranges for which a search parameter exists in a node
 * @param node
 * @param path
 * @param searchParams
 * @param focusedRange If given, will tack on an extra property to the DecoratedRange to
 * show that this range in particular is in focus
 */
export const getSearchRanges = (
  node: Node,
  path: Path,
  searchParams: IBookStore.SearchParams,
  focusedRange?: IBookStore.DecoratedRange
): IBookStore.DecoratedRange[] => {
  const ranges: IBookStore.DecoratedRange[] = [];
  const { q, caseSensitive, wholeWord } = searchParams;

  // only do a search if it is above the minimum number of characters
  if (!Text.isText(node) || q.length < MIN_SEARCH_WORD_LENGTH) {
    return ranges;
  }

  let { text } = node;

  // handle casing
  let search = q;
  if (!caseSensitive) {
    text = text.toLowerCase();
    search = q.toLowerCase();
  }

  let parts: string[];

  //Do a regex match for split to check if it's a whole word
  if (wholeWord) {
    parts = text
      .split(new RegExp(`(?<!\\w)(${sanitizeRegexCharacters(search)})(?!\\S)`))
      .filter((e: string) => e !== search);
  } else {
    parts = text.split(search);
  }

  let offset = 0;

  parts.forEach((part, index) => {
    if (index !== 0) {
      // check if this range is the focused one
      const isFocusedSearchHighlight =
        focusedRange &&
        Path.equals(focusedRange.anchor.path, path) &&
        focusedRange.focus.offset === offset;

      ranges.push({
        anchor: { path, offset: offset - search.length },
        focus: { path, offset },
        select_highlight: !!isFocusedSearchHighlight,
        all_highlight: !isFocusedSearchHighlight,
      });
    }

    offset = offset + part.length + search.length;
  });

  return ranges;
};

const countWordsInTexts = (arr: any[]) => {
  let count = 0;
  arr &&
    arr.forEach((ds) => {
      let s = ds["text"];
      if (s && s.length != 0 && s.match(/\b[-?(\w+)?]+\b/gi)) {
        s = s.replace(/(^\s*)|(\s*$)/gi, "");
        s = s.replace(/[ ]{2,}/gi, " ");
        s = s.replace(/\n /, "\n");
        count += s.split(" ").length;
      }
    });
  return count;
};

export const getWordsCount = (content: Descendant[]): number => {
  let count = 0;
  if (content && content.length > 0) {
    content.forEach((value) => {
      const t = value["type"];
      const d = value["children"];
      const isAligned =
        t === "align_center" || t === "align_left" || t === "align_right";
      const isList = t === "ol" || t === "ul";
      const isCallout = t === "calloutbox";

      if (isAligned || isCallout) {
        count += getWordsCount(d);
      }

      if (isList) {
        d.forEach((li) => {
          const ls = li["children"];
          count += getWordsCount(ls);
        });
      }

      if (!isAligned && !isList && !isCallout) {
        count += countWordsInTexts(d);
      }
    });
  }
  return count;
};

export const getAllSearchRanges = (
  editor: PlateEditor,
  searchParams: IBookStore.SearchParams
): DecoratedRange[] => {
  if (
    !editor?.children?.length ||
    searchParams.q.length < MIN_SEARCH_WORD_LENGTH
  ) {
    return [];
  }

  const matchingNodes = Editor.nodes(editor as BaseEditor, {
    at: [],
    match: (node) =>
      Text.isText(node) &&
      node.text.toLowerCase().includes(searchParams.q.toLowerCase()),
  });
  let nodeMatch = matchingNodes.next();
  const ranges: IBookStore.DecoratedRange[] = [];
  while (!nodeMatch.done) {
    const [node, path] = nodeMatch.value;
    ranges.push(...getSearchRanges(node, path, searchParams));
    nodeMatch = matchingNodes.next();
  }
  return ranges;
};

/**
 * Get the next search match based on where the user's selection currently is.
 * We want to get the *next* match after the cursor, so that if the user is looking
 * at the middle of the doc, they aren't brought up to the first match, but rather the
 * next match. Returns a step number
 * @param editor
 * @param ranges
 */
export const getNextSearchMatchStep = (
  editor: PlateEditor,
  ranges: IBookStore.DecoratedRange[]
): number => {
  // with the ranges, we should set our step accordingly, based on the editor selection
  let step = 0;
  if (editor?.selection) {
    const { anchor: selectionAnchor } = editor.selection;
    // we want to find the first range that is after the current selection
    // use .some + return True to mimic 'break' behavior
    const found = ranges.some((r) => {
      // returns -1, 0, or 1 for before, at, or after
      const pathCompare = Path.compare(r.anchor.path, selectionAnchor.path);
      // this match is above the selection
      if (pathCompare === -1) {
        ++step;
        // this match is in the same node as the selection
      } else if (pathCompare === 0) {
        // this match is before the selection
        if (r.anchor.offset <= selectionAnchor.offset) {
          ++step;
          // this match is after the selection, we found the next one
        } else {
          return true;
        }
      }
      // this match is in a node after the selection, we found the next one
      else {
        return true;
      }
      return false;
    });
    // we never found a match, could be selection is after ALL matches, so instead set to the first one
    // could also consider setting to the last one?
    if (!found) {
      step = 0;
    }
  }
  return step;
};

export const replaceOne = (
  editor: PlateEditor,
  text: string,
  focusedSearch: IBookStore.DecoratedRange
): void => {
  const r = toJS(focusedSearch);
  Transforms.insertText(editor as BaseEditor, text, {
    at: {
      anchor: r.anchor,
      focus: r.focus,
    },
  });
};

export const replaceAll = (
  editor: PlateEditor,
  text: string,
  matchedRanges: IBookStore.DecoratedRange[]
): void => {
  if (!matchedRanges.length) {
    return;
  }
  // we run into a problem when the text we are replacing is not the same length
  // as the text we are replacing it with. we can't just use the ranges we calculated
  // before because of this. This affects when there's multiple matches within a node,
  // so we can keep track of the node matches and calculate the offset diff we need to do
  const originalWordLength = Math.abs(
    matchedRanges[0].anchor.offset - matchedRanges[0].focus.offset
  );
  let sameNodeAdjustment = 0;
  let prevNodePath: Path | undefined;

  toJS(matchedRanges).forEach((range) => {
    const currentPath = range.anchor.path;

    if (prevNodePath && Path.equals(currentPath, prevNodePath)) {
      // this is not the first instance where we replaced text. we need to calculate
      // how much our offsets are off by
      sameNodeAdjustment =
        sameNodeAdjustment + (text.length - originalWordLength);
    } else {
      sameNodeAdjustment = 0;
    }
    Transforms.insertText(editor as BaseEditor, text, {
      at: {
        anchor: {
          ...range.anchor,
          offset: range.anchor.offset + sameNodeAdjustment,
        },
        focus: {
          ...range.focus,
          offset: range.focus.offset + sameNodeAdjustment,
        },
      },
    });

    prevNodePath = range.anchor.path;
  });
};

export const getLeafStyles = (leaf: TText, activeTrackChangeId: string): React.CSSProperties => {
  let styles = {};
  if (leaf.select_highlight) {
    styles = {
      padding: "0.25em",
      borderRadius: 5,
      backgroundColor: "#a0a4f0",
      color: "#3a29b0",
    };
  } else if (leaf.all_highlight) {
    styles = {
      padding: "0.25em",
      borderRadius: 5,
      backgroundColor: "#f6e58d",
      color: "#eb4d4b",
    };
  } else if (leaf.trackChanges){
    const trackChangeLeaf = leaf.trackChanges as TrackChangesProperties;
    const isActiveLeaf = trackChangeLeaf.tcId === activeTrackChangeId;
    switch (trackChangeLeaf.operation) {
      case "insert":
        styles = {
          backgroundColor: isActiveLeaf ? "rgba(189,218,155,0.6)": null,
          color: "green",
          userSelect: "none",
          textDecoration: "underline"
        };
        break;
      case "update":
        styles = {
          backgroundColor: isActiveLeaf ? "rgba(189,218,155,0.6)": null,
          color: "green",
          userSelect: "none",
          textDecoration: "underline"
        };
        break;
      case "delete":
        styles = {
          backgroundColor: isActiveLeaf ? "rgba(252,199,206,0.6)": null,
          color: "#b32e34",
          textDecoration: "line-through",
          userSelect: "none"
        };
        break;
        case "format":
            styles = {
              backgroundColor: isActiveLeaf ? "rgba(189,218,155,0.6)" : null,
              color: "green",
              userSelect: "none",
            };
            break;
    }
  }
  return styles;
};

export const updateAllChaptersJSONCache = async (): Promise<void> => {
  const {chapterIds} = bookStore.book;
  await db.currentBookChaptersJSONCache.clear();
  const promises = chapterIds.map(async (chapterId) => {
    try {
      const chapterChildren = (await getPlateChapterBodyForChapter(chapterId)).chapterBody;
      await db.currentBookChaptersJSONCache.put({_id: chapterId, children: chapterChildren});
    } catch (error) {
      console.error("Error fetching chapter children:", error);
    }
  });

  await Promise.all(promises);
  return;
};

export const updateChapterJSONCache = async (chapterId: string): Promise<void> => {
  try {
    const chapterChildren = (await getPlateChapterBodyForChapter(chapterId)).chapterBody;
    await db.currentBookChaptersJSONCache.put({_id: chapterId, children: chapterChildren});
  } catch (error) {
    console.error("Error fetching chapter children:", error);
  }
  return;
};



export const getChaptersFromJSONCache = async (chapterIds: string[]) => {
  const promises: Promise<any>[] = [];
  for (const chapterId of chapterIds) {
    promises.push(await db.currentBookChaptersJSONCache.get(chapterId) as any);
  }
  const compiledChapters = await Promise.all(promises);
  return compiledChapters;
};