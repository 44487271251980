import React, { useState } from "react";
import { message, Modal } from "antd";

import useRootStore from "../../store/useRootStore";
import { db } from "../../db/bookDb";
import { GetBookFromDB, GetThemeFromIDB } from "../../utils/offline.book.helpers";
import { PdfChapterEndnotes, PdfEndnoteSubheading, PdfSlateEndnote } from "../Previewer/print/types";
import { exportEpubBook } from "../../press/exportEpub";
import { generate } from "randomstring";
import saveAs from "file-saver";
import { epubErrorModalContent } from "../../press/exportEpub/helpers";
import { getFontsForEpub } from "../../press/exportEpub/helpers/getFontsForEpub";
import { ThemeResponse } from "../../types/theme";

import { Button } from "../Shared/Buttons";
import { syncBookBaseData } from "../../utils/sync";
import { getPlateChapterBodyForChapter } from "../../utils/y";
import { TComment } from "../Plate";
import { Text } from "slate";
import { MyRootBlock } from "../Plate/config/typescript";

export default function ExportContainer() {
	const { exportBook, book, setErrorBook, setErrorChapter, getAllEndNotesOfBook, getAllEndNotesByChapter, } = useRootStore().bookStore;
	const { setPDFExproterOptions } = useRootStore().appStore;

	const [ pdfExporting, setPdfExporting ] = useState(false);
	const [ epubExporting, setEpubExporting ] = useState(false);
	const [ docxExporting, setDocxExporting ] = useState(false);
    
	const removeErrorBooks = (bookId: string) => {
		const delErrorChapter =  db.failedChapters.where("_bookId").anyOf(bookId).delete();
		const delErrorBook =  db.failedBooks.where("_bookId").anyOf(bookId).delete();
	};

	const exportEpub = async () => {
		setEpubExporting(true);
		await syncBookBaseData(book._id);
		const theme = await GetThemeFromIDB(book.themeId);
		const {properties: themeStyleProps} = theme as ThemeResponse;
		const fullBook = await GetBookFromDB(book._id, true);
		const {fontFiles, usedFonts} = await getFontsForEpub(themeStyleProps, db.userFavouriteFonts);
		let bookEndnotes: (PdfSlateEndnote | PdfEndnoteSubheading)[] | PdfChapterEndnotes[] = [];
		if(theme?.properties.ePubNotesMode === "END_OF_BOOK"){
			bookEndnotes = theme.properties.ePubEndnotesChapterSettings.categorizeByTitle ? await getAllEndNotesByChapter(): await getAllEndNotesOfBook();
		}
		if(fullBook && theme){
			try {
				// const epub = await exportEpubBook(fullBook, theme, bookEndnotes, fontFiles, usedFonts);
				//TODO:BODY Handle epub export
				const epub = new ArrayBuffer(0);
				saveAs(new Blob([epub]), `${book.title}-${generate(10)}.epub`);
				removeErrorBooks(book._id);
			} catch (e) {
				console.error(e);
				setErrorBook(book._id);
				Modal.confirm(epubErrorModalContent);
			}
		}
		setEpubExporting(false);
	};


	const exportDocx = async () => {
		setDocxExporting(true);
		try {
			const resp = await exportBook(book._id, "docx");
			if(resp && resp.url) window.open(resp.url, "_blank");
		} catch (e: any) {
			Modal.confirm({
				icon: null,
				title: <h2 className="section-heading">Export Failed</h2>,
				content: (
					<div>
						<p style={{ textAlign: "justify" }}>Sorry about that! Your book wasn&apos;t able to be exported due to an error in the document.
						Use the link below to learn more about why this might happen and what you can do to resolve the error.
				</p>
						<a href="https://www.atticus.io/troubleshooting-export-errors/">https://www.atticus.io/troubleshooting-export-errors/</a>

					</div>
				),
				centered: true,
				// onOk: onDeleteBook,
				okText: "Ok",
				okButtonProps: {
					type: "primary",
					danger: true,
					style: {
						flex: 1,
					},
				},
				cancelText: false,
				cancelButtonProps: {
					className: "btn-a",
					style: {
						display: "none",
					},
				},
			});
		}
		setDocxExporting(false);
		// setErrorBook(book._id);
	};

	const exportPdf = async () => {
		setPdfExporting(true);
		try {
			await exportBook(book._id, "pdf");
			message.success("We'll email you your PDF when it's ready", 4);
		} catch (e: any) {
			Modal.confirm({
				icon: null,
				title: <h2 className="section-heading">Export Failed</h2>,
				content: (
					<div>
						<p style={{ textAlign: "justify" }}>Sorry about that! Your book wasn&apos;t able to be exported due to an error in the document.
						Use the link below to learn more about why this might happen and what you can do to resolve the error.
				</p>
						<a target="_blank" href="https://www.atticus.io/troubleshooting-export-errors/" rel="noreferrer">https://www.atticus.io/troubleshooting-export-errors/</a>
					</div>
				),
				centered: true,
				// onOk: onDeleteBook,
				okText: "Ok",
				okButtonProps: {
					type: "primary",
					danger: true,
					style: {
						flex: 1,
					},
				},
				cancelText: false,
				cancelButtonProps: {
					className: "btn-a",
					style: {
						display: "none",
					},
				},
			});
		}
		setPdfExporting(false);
		// setErrorBook(book._id);
	};

	const exportLocalPdf = async () => {
		setPdfExporting(true);
		await syncBookBaseData(book._id);
		setPDFExproterOptions(book._id, book.title);
		setPdfExporting(false);
	};

	const searchForMark = (nodes: MyRootBlock[]) => {
		let found = false;

		const searchNodes = (nodes) => {
			for (const node of nodes) {
				// If the node is a text node and contains marks
				if (Text.isText(node)) {
					if (node["trackChanges"]) {
						found = true;
						return;
					}
					if (node["comments"]) {
						const entries = Object.values(node["comments"]);
						const hasIsResolved = (entries[0] as TComment).isResolved;
						if (!hasIsResolved) {
							found = true;
							return;
						}
					}
				}
				if (node.children) {
					searchNodes(node.children);
				}
			}
		};

		searchNodes(nodes);
		return found;
	};

	const checkUnresolved = async () => {
		let isUnresolved = false;

		const promises = book.chapterIds.map(async (chapterId) => {
			try {
				const chapterChildren = await getPlateChapterBodyForChapter(chapterId);
				const findUnresolved = searchForMark(chapterChildren.chapterBody);
				if (findUnresolved) {
					isUnresolved = true;
				}
			} catch (error) {
				console.error("Error fetching chapter children:", error);
			}
		});

		await Promise.all(promises);
		return isUnresolved;
	};

	const handleExitEdit = async (callback: () => void) => {
		const unresolved = await checkUnresolved();
		if (unresolved) {
			confirmUnresolvedChanges(callback);
		} else {
			callback();
		}
	};

	const confirmUnresolvedChanges = (callback: () => void) => {
		return new Promise<boolean>((resolve) => {
			Modal.confirm({
				icon: null,
				title: <h2 className="section-heading">Unresolved Changes Detected</h2>,
				content: "There are unresolved Track Changes and Comments in some chapters. Do you still want to proceed?",
				centered: true,
				onOk: () => {
					callback();
					resolve(true);
				},
				onCancel: () => {
					resolve(false);
				},

				cancelText: "No",
				cancelButtonProps: {
					className: "btn-a",
					style: {
						flex: 1,
					},
				},
				okText: "Yes",
				okButtonProps: {
					type: "primary",
					style: {
						flex: 1,
					},
				},
			});
		});
	};

	return (
		<div className="export-container-cover">
			<h3 className="export-container-title">Export book</h3>
			<div className="export-container-item-wrapper">
				<Button 
					onClick={() => handleExitEdit(exportEpub)} 
					loading={epubExporting} 
					type="at-primary" 
					backgroundColor="green" 
					size="small" 
					className="export-container-button"
				>
					ePub
				</Button>
				<Button 
					onClick={() => handleExitEdit(exportLocalPdf)} 
					loading={pdfExporting} 
					type="at-primary" 
					backgroundColor="green" 
					size="small" 
					className="export-container-button"
				>
					PDF
				</Button>
				<Button 
					onClick={exportDocx} 
					loading={docxExporting} 
					type="at-primary" 
					backgroundColor="green" 
					size="small" 
					className="export-container-button"
				>
					docx
				</Button>
			</div>
		</div>
	);
}
