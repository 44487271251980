import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Row } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";

import useRootStore from "../../store/useRootStore";

import { AtticusTab, TabProps } from "../../components/Shared/Tabs";
import {
  BookListGrid,
  BookListView,
  BookViewSwitcher,
} from "../../components/Books";
import {
  DeleteIcon,
  SyncIcon,
  DuplicateIcon,
  EditDetailsIcon,
  NoDataIcon,
} from "../../content/icons";
import { Dialog } from "../../components/Shared/Modal";
import { NewBookModal } from "../../components/Books/forms";
import { useOnlineStatus } from "../../utils/hooks/isOffline";
import { validateSort } from "../../utils/helper";
import { EmptyShelf } from "../../components/Shared/Empty";
import { ShelfMasterPageWSMessageData, ShelfWSMessageData } from "../../types/common";
import {
  BOOKSHELF_BOOK_ADDED,
  BOOKSHELF_BOOK_REMOVED,
  BOOKSHELF_MASTERPAGE_REMOVED,
  wsSendShelfUpdateMessage,
} from "../../utils/bookshelf-ws-helper";
import { differenceBy } from "lodash";

type ShelfItemTypes = IBookStore.Book & IBookStore.InitialBook & IChapterStore.IChapterTemplateBase & { shelfType: "book" | "template" | "meta" };

const sortShelfItems = <ShelfItemType extends ShelfItemTypes>(
  shelfItems: ShelfItemType[],
  sortOption: IShelfStore.BookSortOptionType
) => {
  const sortOptionToProperty = {
    "recently-added": "createdAt",
    "date-modified": "modifiedAt",
    "alphabetically-asc": "title",
    "project-asc": "project",
    "author-asc": "author",
    "version-asc": "versionTags"
  };

  const sortBy = sortOptionToProperty[sortOption];

  if (!sortBy) return shelfItems;

  const isSortByDate = sortBy === "createdAt" || sortBy === "modifiedAt";
  const isSortByArray = sortBy === "author" || sortBy === "versionTags";

  const sortedshelfItems = shelfItems.sort((a, b) => {
    if (isSortByDate) {
      return new Date(a[sortBy] || "").getTime() - new Date(b[sortBy] || "").getTime();
    } else {
      return new Date(a["modifiedAt"] || "").getTime() - new Date(b["modifiedAt"] || "").getTime();
    }
  }).reverse();

  return sortedshelfItems.sort((a, b) => {
    if (!isSortByDate) {
      const sortByA = isSortByArray ? (a[sortBy]?.[0] || "").toLowerCase() : (a[sortBy] || "").toString().toLowerCase();
      const sortByB = isSortByArray ? (b[sortBy]?.[0] || "").toLowerCase() : (b[sortBy] || "").toString().toLowerCase();
      if (!sortByA && !sortByB) return 0;
      if (!sortByA) return 1;
      if (!sortByB) return -1;
      if (sortByA > sortByB) return 1;
      if (sortByA < sortByB) return -1;
      return 0;
    }
    return 0;
  });
};

export const BooksTabContainer = observer(() => {
  const { push } = useHistory();
  const { search } = useLocation();
  const listing = new URLSearchParams(search).get("listing");
  const urlSort = new URLSearchParams(search).get("sort") as IShelfStore.BookSortOptionType;
  const urlSearch = new URLSearchParams(search).get("search") as string;
  const urlView = new URLSearchParams(search).get("view") as string;
  const {
    books,
    meta_books,
    sortBy,
    searchTerm,
    view,
    newBookModal,
    deleteBook,
    duplicateBook,
    setModal,
    newBook,
    setSearchTerm,
    setSortBy,
    setView,
  } = useRootStore().shelfStore;
  const { chapterTemplates, deleteChapterTemplate, syncChapterTemplate } =
    useRootStore().chapterStore;
  const { book } = useRootStore().bookStore;
  const { socket } = useRootStore().bookSyncWebSocketStore;
  const { user } = useRootStore().authStore;

  const isOnline = useOnlineStatus();

  useEffect(() => {
    setSortBy(validateSort(urlSort));
    setSearchTerm(urlSearch || "");
    setView(urlView === "grid" || urlView === "list" ? urlView : "grid");
  }, []);

  const handleTabChange = ((activeKey: string) => {
    const params = new URLSearchParams(search);
    params.set("listing", activeKey);
    const queryString = params.toString();
    push({
      search: queryString,
    });
  });

  const preventDefaultAction = (event: MenuInfo) => {
    event.domEvent.preventDefault();
    event.domEvent.stopPropagation();
    return;
  };

  const handleDeleteBook = (book: IBookStore.Book) =>
    Dialog({
      open: false,
      title: "Delete book",
      content: `Are you sure you want to delete '${book.title}'?`,
      leftBtn: {
        children: "No",
      },
      rightBtn: {
        danger: true,
        onClick: async () => {
          deleteBook(book._id);
          if (user) {
            const data: ShelfWSMessageData = {
              userId: user._id,
              bookId: book._id,
              isCollabBook: false,
            };
            wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_REMOVED, data);
          }
        },
        children: "Yes",
      },
    });

  const handleDuplicateBook = (book: IBookStore.Book) =>
    Dialog({
      open: false,
      title: "Duplicate book",
      content: `Are you sure you want to duplicate '${book.title}'?`,
      leftBtn: {
        children: "No",
      },
      rightBtn: {
        danger: true,
        onClick: async () => await duplicateBook(book._id),
        children: "Yes",
      },
    });

  const handleDeleteTemplate = (temp: IChapterStore.IChapterTemplateBase) =>
    Dialog({
      open: false,
      title: "Delete master page",
      content: `Are you sure you want to delete '${temp.title}'?`,
      leftBtn: {
        children: "No",
      },
      rightBtn: {
        danger: true,
        onClick: () => {
          deleteChapterTemplate(temp._id).then(() => {
            if (user) {
              const data: ShelfMasterPageWSMessageData = {
                userId: user._id,
                templateId: temp._id
              };
              wsSendShelfUpdateMessage(socket, BOOKSHELF_MASTERPAGE_REMOVED, data);
            }
          });
        },
        children: "Yes",
      },
    });

  const handleTemplateSync = (temp: IChapterStore.IChapterTemplateBase) =>
    Dialog({
      open: false,
      title: "Sync master pages",
      content: `Are you sure you want to sync '${temp.title}' to all books using this master page ?`,
      leftBtn: {
        children: "No",
      },
      rightBtn: {
        danger: true,
        onClick: () => syncChapterTemplate(true, false, book._id, temp._id, temp.motherChapterId),
        children: "Yes",
      },
    });

  const handleShelfButtonClick = (
    buttonType: IShelfStore.ShelfButtonItemType
  ) => {
    if (buttonType === "create-book") {
      setModal("newBookModal", true);
    }
  };
  
  const combinedItems = useMemo(() => {
    const filteredBooks = toJS(books).filter(book => !book.collaborated).map((book) => ({ ...book, shelfType: "book" }));
    const filteredChapterTemplates = toJS(chapterTemplates).map((template) => ({ ...template, shelfType: "template" }));
    const filteredMetas = differenceBy(toJS(meta_books), filteredBooks, "_id").map((book) => ({ ...book, shelfType: "meta" }));

    return [
      ...filteredMetas,
      ...filteredBooks,
      ...filteredChapterTemplates
    ] as ShelfItemTypes[];
  }, [books, chapterTemplates, meta_books, isOnline]);

  const { allItems, parsedBooks, parsedTemplates } = useMemo(() => {
    const allItems: IShelfStore.ShelfItem[] = [];
    const parsedBooks: IShelfStore.ShelfItem[] = [];
    const parsedTemplates: IShelfStore.ShelfItem[] = [];

    sortShelfItems(combinedItems, validateSort(urlSort) || sortBy)
      .filter(
        (item) =>
          [
            item.title || "",
            item.project || "",
            ...(item.author || []),
            ...(item.versionTags || [])
          ].filter(
            (term) => term.toLowerCase().includes((urlSearch || searchTerm).toLowerCase())
          ).length > 0
      )
      .map((item) => {
        const parsedItem = {
          item,
          type: item.shelfType,
          actionItem:
            item.shelfType === "book"
              ? isOnline
                ? [
                  {
                    key: "book-duplicate",
                    label: "Duplicate",
                    icon: <DuplicateIcon />,
                    onClick: (event) => {
                      preventDefaultAction(event);
                      handleDuplicateBook(item as IBookStore.Book);
                    },
                  },
                  {
                    key: "book-delete",
                    label: "Delete",
                    icon: <DeleteIcon />,
                    onClick: (event) => {
                      preventDefaultAction(event);
                      handleDeleteBook(item as IBookStore.Book);
                    },
                  },
                ]
                : []
              : isOnline
                ? [
                  {
                    key: "template-edit-details",
                    label: "Edit details",
                    icon: <EditDetailsIcon />,
                    onClick: () => push(`/masterpage/${item._id}`),
                  },
                  {
                    key: "template-sync",
                    label: "Sync to all books",
                    icon: <SyncIcon />,
                    onClick: (event) => {
                      preventDefaultAction(event);
                      handleTemplateSync(
                        item as IChapterStore.IChapterTemplateBase
                      );
                    },
                  },
                  {
                    key: "template-delete",
                    label: "Delete",
                    icon: <DeleteIcon />,
                    onClick: (event) => {
                      preventDefaultAction(event);
                      handleDeleteTemplate(
                        item as IChapterStore.IChapterTemplateBase
                      );
                    },
                  },
                ]
                : [
                  {
                    key: "template-edit-details",
                    label: "Edit details",
                    icon: <EditDetailsIcon />,
                    onClick: () => push(`/masterpage/${item._id}`),
                  },
                ],
        };

        allItems.push(parsedItem as IShelfStore.ShelfItem);
        if (item.shelfType === "book") {
          parsedBooks.push(parsedItem as IShelfStore.ShelfItem);
        } else {
          parsedTemplates.push(parsedItem as IShelfStore.ShelfItem);
        }
      });
    return {
      allItems,
      parsedBooks,
      parsedTemplates,
    };
  }, [books, chapterTemplates, combinedItems, urlSort, sortBy, urlSearch, searchTerm, view, isOnline]);

  const createNewBookButtonItem: IShelfStore.ShlefButtonItem = {
    type: "create-book",
  };

  // render accordingly based on the type (Boxsets, Books and Masterpages)
  const renderList = (
    items: (IShelfStore.ShelfItem | IShelfStore.ShlefButtonItem)[]
  ) =>
    items.length > 0 ? (
      view === "list" ? (
        <BookListView
          items={items}
          handleButtonClick={handleShelfButtonClick}
        />
      ) : (
        <BookListGrid
          items={items}
          handleButtonClick={handleShelfButtonClick}
        />
      )
    ) : null;


  const listFallback = (title: string, itemLength: number) => itemLength > 0 ? null : <EmptyShelf title={title} />;

  const tabItems: TabProps[] = [
    {
      key: "all",
      label: "All",
      count: allItems.length,
      children: allItems.length > 0 ? renderList(allItems) : listFallback("No Books Yet!", combinedItems.length),
    },
    {
      key: "books",
      label: "Books",
      count: parsedBooks.length,
      children:
        parsedBooks.length > 0 ? (
          renderList([createNewBookButtonItem, ...parsedBooks])
        ) : listFallback("No Books Yet!", books.length),
    },
    {
      key: "masterpages",
      label: "Master Pages",
      count: parsedTemplates.length,
      children:
        parsedTemplates.length > 0 ? (
          renderList(parsedTemplates)
        ) : listFallback("No Master Pages Yet!", chapterTemplates.length),
    },
  ];

  return (
    <>
      <>
        <div>
          <AtticusTab
            variant="primary-blue"
            defaultActiveKey={listing || "all"}
            items={tabItems}
            tabBarExtraContent={<BookViewSwitcher />}
            onChange={handleTabChange}
          />
        </div>
        <NewBookModal
          showModal={newBookModal}
          onNewBook={async (params) => {
            await newBook(params);
            if (user) {
              const data: ShelfWSMessageData = {
                userId: user._id,
                bookId: params._id,
                isCollabBook: false,
              };
              wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_ADDED, data);
            }
            setModal("newBookModal", false);
            push(`/books/${params._id}/${params.chapterId}`);
          }}
          onClose={() => setModal("newBookModal", false)}
        />
      </>
    </>
  );
});
