import React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { useHistory } from "react-router-dom";
import { Col, Row } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";

import useRootStore from "../store/useRootStore";
import { AtticusLoader } from "../components/Shared/Loader/AtticusLoader";
import { Container, ScrollContainer } from "../components/Shared/Layouts";
import { TextAndIconLink } from "../components/Shared/Links/TextWithIconLink";
import { BookActionSection, } from "../components/Books";
import { Dialog } from "../components/Shared/Modal";
import { BookCardView } from "../components/Shared/Cards";
import { DeleteIcon, SyncIcon, DuplicateIcon, EditDetailsIcon, NoDataIcon } from "../content/icons";
import { useOnlineStatus } from "../utils/hooks/isOffline";
import { EmptyShelf } from "../components/Shared/Empty";

export const Overview = observer(() => {
  const { push } = useHistory();
  const { mounting, deleteBook, duplicateBook, books } = useRootStore().shelfStore;
  const { chapterTemplates, deleteChapterTemplate, syncChapterTemplate } = useRootStore().chapterStore;
  const { book } = useRootStore().bookStore;

  const recent_books = toJS(books).filter(book => !book.collaborated).sort((a, b) => new Date(a.modifiedAt || "").getTime() - new Date(b.modifiedAt || "").getTime()).reverse();

  const isOnline = useOnlineStatus();

  const preventDefaultAction = (event: MenuInfo) => {
    event.domEvent.preventDefault();
    event.domEvent.stopPropagation();
    return;
  };

  const handleDeleteBook = (book: IBookStore.Book) => Dialog({
    open: false,
    title: "Delete book",
    content: `Are you sure you want to delete '${book.title}'?`,
    leftBtn: {
      children: "No",
    },
    rightBtn: {
      danger: true,
      onClick: async () => await deleteBook(book._id),
      children: "Yes",
    }
  });

  const handleDuplicateBook = (book: IBookStore.Book) => Dialog({
    open: false,
    title: "Duplicate book",
    content: `Are you sure you want to duplicate '${book.title}'?`,
    leftBtn: {
      children: "No",
    },
    rightBtn: {
      type: "at-primary",
      onClick: async () => await duplicateBook(book._id),
      children: "Yes"
    }
  });

  const handleDeleteTemplate = (temp: IChapterStore.IChapterTemplateBase) => Dialog({
    open: false,
    title: "Delete master page",
    content: `Are you sure you want to delete '${temp.title}'?`,
    leftBtn: {
      children: "No",
    },
    rightBtn: {
      danger: true,
      onClick: () => deleteChapterTemplate(temp._id),
      children: "Yes"
    }
  });

  const handleTemplateSync = (temp: IChapterStore.IChapterTemplateBase) => Dialog({
    open: false,
    title: "Sync master page",
    content: `Are you sure you want to sync '${temp.title}' to all books using this master page ?`,
    leftBtn: {
      children: "No",
    },
    rightBtn: {
      danger: true,
      onClick: () => syncChapterTemplate(true, false, book._id, temp._id, temp.motherChapterId),
      children: "Yes"
    }
  });

  const parsedBooks: IShelfStore.ShelfItem[] = recent_books.map((book) => ({
    item: book,
    type: "book",
    actionItem: [
      { key: "book-duplicate", label: "Duplicate", icon: <DuplicateIcon />, onClick: (event) => { preventDefaultAction(event); handleDuplicateBook(book); } },
      { key: "book-delete", label: "Delete", icon: <DeleteIcon />, onClick: (event) => { preventDefaultAction(event); handleDeleteBook(book); } },
    ]
  }));

  const parsedTemplates: IShelfStore.ShelfItem[] = toJS(chapterTemplates).map((temp) => ({
    item: temp,
    type: "template",
    actionItem: isOnline ? [
      { key: "template-edit-details", label: "Edit details", icon: <EditDetailsIcon />, onClick: () => push(`/masterpage/${temp._id}`) },
      { key: "template-sync", label: "Sync to all books", icon: <SyncIcon />, onClick: (event) => { preventDefaultAction(event); handleTemplateSync(temp); } },
      { key: "template-delete", label: "Delete", icon: <DeleteIcon />, onClick: (event) => { preventDefaultAction(event); handleDeleteTemplate(temp); } },
    ] : [
      { key: "template-edit-details", label: "Edit details", icon: <EditDetailsIcon />, onClick: () => push(`/masterpage/${temp._id}`) },
    ]
  }));

  return (
    <ScrollContainer className="padded">
       {mounting ? (
        <AtticusLoader loading={mounting} />
      ) : (
      <Container>
        <BookActionSection />

        {!parsedBooks.length && !parsedTemplates.length ? (
          <EmptyShelf
            title="Let's Write a Book!"
          />
        ) : null}

        {parsedBooks && parsedBooks.length > 0 ? (
          <>
            <Row className="inner-s" justify="space-between" align="bottom">
              <Col>
                <p className="home-page-section-heading">Recent work</p>
              </Col>
              <Col>
                <TextAndIconLink link={"/my-books?listing=all&sort=date-modified"} text={"See all"} />
              </Col>
            </Row>
            <div className={`book-list ${parsedBooks.length > 0 ? "single-row" : "no-data"}`}>
              {parsedBooks.length > 0 ? parsedBooks.map((book, i) => (
                <BookCardView
                  item={book}
                  key={i}
                />
              )) : null}
            </div>
          </>
        ) : null}

        {chapterTemplates && chapterTemplates.length > 0 ? (
          <div>
            <Row className="inner-s" justify="space-between" align="bottom">
              <Col>
                <p className="home-page-section-heading">Master Pages</p>
              </Col>
              <Col>
                <TextAndIconLink link={"/my-books?listing=masterpages"} text={"See all"} />
              </Col>
            </Row>
            <div className="book-list single-row">
              {parsedTemplates.map((template, i) => (
                <BookCardView
                  item={template}
                  key={i}
                />
              ))}
            </div>
          </div>
        ) : null}
      </Container>
      )}
    </ScrollContainer>
  );
});
