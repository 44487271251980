import axios, { AxiosInstance } from "axios";

import authStore from "../store/Auth";
import { InitialBook, RemoteBook } from "../types/book";
import { IPresetBase } from "../types/presets";
import { IChapterTemplateBase, ChapterMeta, RemoteChapterMeta } from "../types/chapter";
import {
  CreateWritingHabit,
  WritingHabit,
} from "./../components/Goals/WritingHabit/types";
import { YHTTPSyncReqPayload, YHTTPSyncResPayload } from "../types/sync";
import { AxiosInstanceConfig } from "../types/common";
import { MyRootBlock } from "../components/Plate/config/typescript";

const endpoints = {
  theme: {
    get: "/book-themes",
    post: "/book-themes",
    put: (id: string) => "/book-themes/" + id,
    delete: (id: string) => "/book-themes/" + id,
  },
  theme_favs: {
    add: "/book-theme-favourites",
    delete: (id: string) => "/book-theme-favourites/" + id,
  },
  books: {
    get_count: "books/count",
    get_initial: "/books/initial",
    get_all: "/books",
    get: (id: string) => `/books/${id}`,
    put: (id: string) => `/books/${id}`,
    patch: (id: string) => `/books/${id}`,
    delete: (id: string) => `/books/${id}`,
    duplicate: (id: string) => `/books/${id}/clone`,
    remove_access: (id: string) => `/books/${id}/remove-access`,
    switch_theme: (bookId: string) => `/books/${bookId}/theme`,
    validate: "/books/validate",
  },
  writing_habit: {
    get: "/writing-habits",
    post: "/writing-habits",
    put: "/writing-habits",
    delete: "/writing-habits",
  },
  fonts: {
    get: "/fonts",
    favourite: {
      post: "/fonts/favourite",
      get: "/fonts/user-favourites",
      delete: (fontid: string) => `/fonts/favourite/${fontid}`,
    }
  },
  collaboration: {
    get: "/bookCollaborator",
    get_collaborators: "/bookCollaborator/collaborators",
    get_collaborated: "bookCollaborator/collaborated",
    get_collaboratedBook: (book_id: string) => `bookCollaborator/collaboratedBook/${book_id}`,
    post: "/bookCollaborator",
    activate_post: "/bookCollaborator/activate",
    pending_post: "/bookCollaborator/pending",
    get_bookcollaborators: (book_id: string) =>`/bookCollaborator/collaborators/${book_id}`,
    put_type: (collaboration_id: string) =>  `/bookCollaborator/${collaboration_id}/type`,
    delete: (collaboration_id: string) =>  `/bookCollaborator/${collaboration_id}`,
    delete_user: (collaborator_id: string) =>  `/bookCollaborator/user/${collaborator_id}`,
  },
  notification: {
    get_unread_count: "/notification/count",
    post_dismiss_all: "/notification/dismiss",
    get: (page: number, count: number) => `/notification?page=${page}&count=${count}`,
    get_one: (id: string) => `/notification/${id}`,
    post_read: (id: string) => `/notification/${id}/read`,
    post_dismiss: (id: string) => `/notification/dismiss/${id}`
  }
};


const getAxiosInstance = (config?: AxiosInstanceConfig): AxiosInstance => {
  let headers: { Authorization: string; } | null = null;
  if(!config?.excludeAuthHeader){
    const token = authStore.token;
    headers =  { Authorization: `Bearer ${token}` };
  }
  const baseURL = config?.baseUrl || process.env.REACT_APP_API_HOST;
  const instance = axios.create({
    headers, 
    baseURL
  });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        if (
          !(
            window.location.pathname === "/auth/sign-out" ||
            window.location.pathname === "/auth/sign-in"
          )
        ) {
          window.location.href = "/auth/sign-out?expired=true";
        }
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

export interface BaseResponse {
  success: boolean;
}

export interface AddCollaboratorResponse extends BaseResponse {
  active_user: ICollabStore.AuthorMeta,
  collaboration: ICollabStore.BookCollaboration | null
}

export interface AddPendingCollaboratorResponse extends BaseResponse {
  collaboration: ICollabStore.BookCollaboration | null
}

interface UpdateResponse {
  timestamp: Date;
}

interface InviteDetails {
  firstName: string;
  lastName: string;
}

interface SignUpRequest {
  inviteId: string;
  inviteCode: string;
  firstName: string;
  lastName: string;
  password: string;
}

interface ImportBookRequest {
  title: string;
  author: string[];
  project: string;
  url: string;
}

interface ImportChapterRequest {
  url: string;
  bookId: string;
  insertIntoVolume: boolean;
}
interface CreateChapterTemplateReturnResponse {
  timestamp: Date;
  templateId: string;
}

interface ImportBookResponse {
  bookId: string;
}

interface ImportChapterResponse {
  bookId: string;
}

export interface ProfileImage {
  file: any;
  profilePictureURL: string;
}

export interface ExportResponse {
  _id: string;
  bookId: string;
  processed: boolean;
  url: string;
  type: "pdf" | "epub";
  createdAt: Date;
  lastUpdateAt: Date;
  __v: number;
}

export interface Password {
  oldPassword: string;
  newPassword: string;
}

export interface FileUpload {
  fileBinary: Blob;
  fileId: string;
  path?: string;
  fileName?: string;
  contentType?: string;
}

interface CreateBoxsetRequest {
  title: string;
  author: string[];
  project: string;
  bookIds: string[];
}

interface CreateBoxsetResponse {
  bookId: string;
}

interface CreateBooklinkerSuccessRes {
  success: true,
  linkResult: string,
}
interface CreateBooklinkerErrorRes {
  success: false,
  responseStatus: {
    errorCode: string,
    message: string
  },
}

interface GetNotificationResponse {
  notifications: INotificationStore.Notification[],
}

export type CreateBooklinkerResponse = CreateBooklinkerSuccessRes | CreateBooklinkerErrorRes;

export class AtticusClient {
  // Auth functions
  public static async FetchInvite(
    inviteId: string,
    inviteCode: string
  ): Promise<InviteDetails> {
    const authResp = await getAxiosInstance({excludeAuthHeader: true}).get(`/auth/invite/${inviteId}`, {
      params: {
        inviteCode,
      },
    });
    return authResp.data;
  }

  public static async SignUp(
    values: SignUpRequest
  ): Promise<{ token: string; newBookToken: string; abilitiesPublicKey: string; [key: string]: any }> {
    const authResp = await getAxiosInstance({excludeAuthHeader: true}).post("/auth/signup", values);
    return authResp.data;
  }

  public static async SignIn(
    email: string,
    password: string
  ): Promise<{ token: string; newBookToken: string; abilitiesPublicKey: string; [key: string]: any }> {
    const authResp = await getAxiosInstance({excludeAuthHeader: true}).post("/auth/signin", {
      email,
      password,
    });
    return authResp.data;
  }

  public static async ForgotPassword(
    email: string
  ): Promise<{ token: string; [key: string]: any }> {
    const authResp = await getAxiosInstance({excludeAuthHeader: true}).post("/auth/forgotPassword", {
      email,
    });
    return authResp.data;
  }

  public static async ResetPassword(
    userId: string,
    code: string,
    newPassword: string
  ): Promise<unknown> {
    const authResp = await getAxiosInstance({excludeAuthHeader: true}).post("/auth/resetPassword", {
      userId,
      code,
      newPassword,
    });
    return authResp.data;
  }

  // Authenticated Functions
  public static async GetBookCount(): Promise<{
    count: number;
  }> {
    const booksResp = await getAxiosInstance().get(endpoints.books.get_count);
    return booksResp.data;
  }


  public static async GetInitialBooks(batch: number, count: number): Promise<{
    books: InitialBook[];
  }> {
    const booksResp = await getAxiosInstance().get(endpoints.books.get_initial, {
      params: {
        batch,
        count
      }
    });
    return booksResp.data;
  }

  public static async GetInitialCollabBooks(): Promise<{
    collabBooks: InitialBook[];
    collaborations: ICollabStore.BookCollaboration[];
  }> {
    const booksResp = await getAxiosInstance().get("/books/initial-collaborated");
    return booksResp.data;
  }
  
  public static async GetBooks(): Promise<{
    books: RemoteBook[];
    deletedBookIds: string[];
  }> {
    const booksResp = await getAxiosInstance().get(endpoints.books.get_all);
    return booksResp.data;
  }

  public static async ValidateBook(book: RemoteBook): Promise<UpdateResponse> {
    const putBookResp = await getAxiosInstance().post(endpoints.books.validate, book);
    return putBookResp.data;
  }

  public static async GetBook(bookId: string): Promise<RemoteBook> {
    const bookResp = await getAxiosInstance().get<RemoteBook>(endpoints.books.get(bookId));
    return bookResp.data;
  }

  public static async PutBook(book: RemoteBook): Promise<UpdateResponse> {
    const putBookResp = await getAxiosInstance().put(endpoints.books.put(book._id), book);
    return putBookResp.data;
  }

  public static async PatchBook(
    bookId: string,
    changes: Partial<RemoteBook>
  ): Promise<UpdateResponse> {
    const patchBookResp = await getAxiosInstance().patch(
      endpoints.books.patch(bookId),
      changes
    );
    return patchBookResp.data;
  }

  public static async DuplicateBook(bookId: string): Promise<string> {
    const postBookResp = await getAxiosInstance().post(endpoints.books.duplicate(bookId));
    return postBookResp.data;
  }

  public static async DeleteBook(bookId: string): Promise<UpdateResponse> {
    const deleteBookResp = await getAxiosInstance().delete(endpoints.books.delete(bookId));
    return deleteBookResp.data;
  }

  public static async RemoveAccessFromCollabBook(bookId: string): Promise<UpdateResponse> {
    return await getAxiosInstance().patch(endpoints.books.remove_access(bookId));
  }

  // Chapter Updates
  public static async PutChapter(chapter: RemoteChapterMeta): Promise<UpdateResponse> {
    const putChapterResp = await getAxiosInstance().put(
      `/books/${chapter.bookId}/chapters/${chapter._id}`,
      chapter
    );
    return putChapterResp.data;
  }

  public static async PatchChapter(
    bookId: string,
    chapterId: string,
    changes: Partial<RemoteChapterMeta>
  ): Promise<UpdateResponse> {
    const patchBookResp = await getAxiosInstance().patch(
      `/books/${bookId}/chapters/${chapterId}`,
      changes
    );
    return patchBookResp.data;
  }

  public static async DeleteChapter(
    bookId: string,
    chapterId: string
  ): Promise<UpdateResponse> {
    const deleteBookResp = await getAxiosInstance().delete(
      `/books/${bookId}/chapters/${chapterId}`
    );
    return deleteBookResp.data;
  }

  public static async UpdateThemeInBook(
    bookId: string,
    themeId: string
  ): Promise<any> {
    const response = await getAxiosInstance().post(
      endpoints.books.switch_theme(bookId),
      { themeId }
    );
    return response.data;
  }

  public static async GetThemes(): Promise<IThemeStore.ThemeResponse[]> {
    const response = await getAxiosInstance().get(endpoints.theme.get);
    return response.data;
  }

  public static async SaveNewTheme(
    theme: Omit<IThemeStore.ThemePayload, "_id">
  ): Promise<IThemeStore.ThemeResponse> {
    const response = await getAxiosInstance().post(endpoints.theme.post, theme);
    return response.data;
  }

  public static async SaveTheme(
    theme: IThemeStore.Theme
  ): Promise<IThemeStore.ThemeResponse> {
    const response = await getAxiosInstance().put(
      endpoints.theme.put(theme._id),
      theme
    );
    return response.data;
  }

  public static async DeleteTheme(themeId: string): Promise<any> {
    const response = await getAxiosInstance().delete(
      endpoints.theme.delete(themeId)
    );
    return response.data;
  }

  public static async AddThemeToFavourites(themeId: string): Promise<any> {
    const response = await getAxiosInstance().post(endpoints.theme_favs.add, {
      themeId,
    });
    return response.data;
  }

  public static async RemoveThemeFromFavourites(
    themeId: string
  ): Promise<UpdateResponse> {
    const deleteBookResp = await getAxiosInstance().delete(
      endpoints.theme_favs.delete(themeId)
    );
    return deleteBookResp.data;
  }

  public static async ImportDocument(
    params: ImportBookRequest,
    fileType?: string
  ): Promise<ImportBookResponse> {
    const importResp = await getAxiosInstance().post<ImportBookResponse>(
      `/books/import/${fileType ? fileType : "docx" || "mobi"}`,
      params
    );
    return importResp.data;
  }

  public static async ImportChapters(
    params: ImportChapterRequest
  ): Promise<ImportChapterResponse> {
    const response = await getAxiosInstance().post(
      "/books/import-chapters",
      params
    );
    return response.data;
  }
  public static async ExportBook(
    bookId: string,
    type: "pdf" | "epub" | "docx",
    compiledBook?: IBookStore.ExpandedBook,
  ): Promise<ExportResponse> {
    const exportResp = await getAxiosInstance().post(
      `/books/${bookId}/export/${type}`, {
        compiledBook
      }
    );
    return exportResp.data;
  }

  public static async SaveSnapshot(
    bookId: string,
    json: string
  ): Promise<boolean> {
    const snapshotResp = await getAxiosInstance({excludeAuthHeader: true}).post("/snapshots", {
      bookId,
      json,
    });
    return snapshotResp.data;
  }

  public static async createBoxset(
    params: CreateBoxsetRequest
  ): Promise<CreateBoxsetResponse> {
    const response = await getAxiosInstance().post("/books/boxset", params);
    return response.data;
  }

  // Profile related functions
  public static async GetProfile(): Promise<any> {
    const profile = await getAxiosInstance().get("/me");
    return profile.data;
  }

  public static async UpdatePassword(
    changes: Partial<Password>
  ): Promise<ExportResponse> {
    const profile = await getAxiosInstance().post("/me/updatePassword", changes);
    return profile.data;
  }

  public static async PatchProfile(
    changes: Partial<IAuthStore.ProfileFields>
  ): Promise<UpdateResponse> {
    const patchProfileResp = await getAxiosInstance().patch("/me", changes);
    return patchProfileResp.data;
  }

  public static async SetBookGoal(goal: any): Promise<any> {
    const bookGoal = await getAxiosInstance().post("/goals/setGoal", goal);
    return bookGoal.data;
  }

  public static async FetchLatestGoal(
    bookId: string,
    writtenWordCount: number,
    sDate: any
  ): Promise<any> {
    const bookGoal = await getAxiosInstance().post("/goals/fetchGoal", {
      bookId,
      writtenWordCount,
      sDate,
    });
    return bookGoal.data;
  }

  public static async EditGoal(goal: any): Promise<any> {
    const bookGoal = await getAxiosInstance().put("/goals/editGoal", goal);
    return bookGoal.data;
  }

  public static async GetProjectGoal(bookId: string): Promise<any> {
    const bookGoal = await getAxiosInstance().get(`/goals/getGoal/${bookId}`);
    return bookGoal.data;
  }

  public static async DeleteGoal(bookId: string, goalId: string): Promise<any> {
    const bookGoal = await getAxiosInstance().put("/goals/deleteGoal", {
      bookId,
      goalId,
    });
    return bookGoal.data;
  }

  public static async CompleteGoal(
    bookId: string,
    goalId: string,
    totalWrittenWordCount: number
  ): Promise<any> {
    const bookGoal = await getAxiosInstance().put("/goals/completeGoal", {
      bookId,
      goalId,
      totalWrittenWordCount,
    });
    return bookGoal.data;
  }

  public static async CreateHabit(
    habit: CreateWritingHabit
  ): Promise<WritingHabit> {
    const writingHabit = await getAxiosInstance().post(
      endpoints.writing_habit.post,
      habit
    );
    return writingHabit.data;
  }

  public static async UpdateHabit(habit: WritingHabit): Promise<WritingHabit> {
    const writingHabit = await getAxiosInstance().put(
      endpoints.writing_habit.put,
      habit
    );
    return writingHabit.data;
  }

  public static async GetHabit(): Promise<WritingHabit | null> {
    const writingHabit = await getAxiosInstance().get<WritingHabit | null>(
      endpoints.writing_habit.get
    );
    return writingHabit.data;
  }

  public static async DeleteHabit(): Promise<void> {
    await getAxiosInstance().delete(endpoints.writing_habit.delete);
  }

  public static async CreateChapterTemplate(
    chapterId: string,
    chapterBody: MyRootBlock[],
    templateName: string,
    section: string
  ): Promise<CreateChapterTemplateReturnResponse> {
    const postChapResp = await getAxiosInstance().post(
      `/chapter-templates/${chapterId}`,
      {
        chapterBody,
        templateName,
        section,
      }
    );
    return postChapResp.data;
  }

  public static async CreateChapterFromTemplate(
    templateId: string,
    bookId: string
  ): Promise<UpdateResponse> {
    const postChapResp = await getAxiosInstance().post(
      `/chapter-templates/clone/${templateId}/${bookId}`
    );
    return postChapResp.data;
  }

  public static async GetChapterTemplates(): Promise<IChapterTemplateBase[]> {
    const templateResp = await getAxiosInstance().get(
      "/chapter-templates/filtered"
    );
    return templateResp.data;
  }

  public static async GetChaptersToUpdateWithChapterTemplate(
    templateId: string,
    updateAllBooks?: boolean,
    bookId?: string
  ): Promise<string[] | null> {
    const chaptersToUpdate = await getAxiosInstance().get(
      "/chapter-templates/chapters-to-update", {
        params: {
          templateId,
          updateAllBooks,
          bookId
        }
      }
    );
    return chaptersToUpdate.data;
  }

  public static async SyncChapterTemplate(
    updateAllBooks: boolean,
    updateTemplate: boolean,
    bookId?: string,
    templateId?: string,
    chapterId?: string,
    updatedChapterBody?: MyRootBlock[],
  ): Promise<any> {
    const templateResp = await getAxiosInstance().put(
      `/chapter-templates/${templateId}/sync`,
      {
        updateAllBooks,
        updateTemplate,
        bookId,
        chapterId,
        updatedChapterBody
      }
    );
    return templateResp.data;
  }

  public static async DeleteChapterTemplate(templateId: string): Promise<any> {
    const templateResp = await getAxiosInstance().delete(
      `/chapter-templates/${templateId}`
    );
    return templateResp.data;
  }

  public static async UpdateChapterTemplate(
    templateId: string,
    update: IChapterTemplateBase
  ): Promise<any> {
    const templateResp = await getAxiosInstance().put(
      `/chapter-templates/${templateId}`,
      update
    );
    return templateResp.data;
  }

  public static async PatchChapterTemplate(
    templateId: string,
    changes: Partial<IChapterTemplateBase>
  ): Promise<UpdateResponse> {
    const patchBookResp = await getAxiosInstance().patch(
      `/chapter-templates/${templateId}`,
      changes
    );
    return patchBookResp.data;
  }

  public static async ChapterTemplateChangesSync(templateId : string): Promise<void> {
    await getAxiosInstance().post("/chapter-templates/updates-sync", {templateId});
  }

  public static async DeleteImgInGallery(
    imgData: any,
    type: string
  ): Promise<any> {
    const img = await getAxiosInstance().post("/imgGallery/deleteImg", {
      imgData,
      type,
    });
    return img.data;
  }

  public static async AddImgToGallery(imgData: any): Promise<any> {
    const img = await getAxiosInstance().post("/imgGallery/addImage", {
      imgData,
    });
    return img.data;
  }

  public static async GetGallery(userId: any): Promise<any> {
    const img = await getAxiosInstance().get(`/imgGallery/getGallery/${userId}`);
    return img.data;
  }

  public static async GetProfiles(): Promise<any> {
    const profileData = await getAxiosInstance().get("/profiles/getProfiles");
    return profileData;
  }

  public static async CreateProfile(
    profile: ISocialProfileStore.ICreateSMProfile
  ): Promise<any> {
    const profileData = await getAxiosInstance().post(
      "/profiles/createProfile",
      { profile }
    );
    return profileData;
  }

  public static async UpdateProfile(
    profile: Partial<ISocialProfileStore.ISMProfile>
  ): Promise<any> {
    const profileData = await getAxiosInstance().put("/profiles/updateProfile", {
      profile,
    });
    return profileData;
  }

  public static async DeleteProfile(profileId: string): Promise<any> {
    const profileData = await getAxiosInstance().delete(
      `/profiles/deleteProfile/${profileId}`
    );
    return profileData;
  }

  // Presets
  public static async GetPresets( type: string ): Promise<IPresetBase[]> {
    try {
      const presets = await getAxiosInstance().post("/presets/getPresetsByType", 
      { type });
      return presets.data;
    }catch(err){
      console.log(err);
      return [];
    }
  }

  public static async CreatePreset( preset: IPresetStore.ICreatePresets ): Promise<IPresetBase | null> {
    try {
      const response = await getAxiosInstance().post("/presets/createPreset", 
      { preset });
      return response.data;
    }catch(err){
      console.log(err);
      return null;
    }
  }

  public static async DeletePreset( presetId: string ): Promise<IPresetBase | null> {
    try {
      const response = await getAxiosInstance().delete(`/presets/deletePreset/${presetId}`);
      return response.data;
    }catch(err){
      console.log(err);
      return null;
    }
  }

  /** HTTP sync for ydocs */

  public static async SyncDocument(syncPayload: YHTTPSyncReqPayload): Promise<YHTTPSyncResPayload> {
    const syncBaseUrl = process.env.REACT_APP_SYNC_API_HOST_HTTP;
    const syncResponse = await getAxiosInstance({baseUrl: syncBaseUrl}).post(
      "/httpsync",
      syncPayload,
    );
    return syncResponse.data as YHTTPSyncResPayload;
  }
	/**
	 * Accepts an array of file objects to upload and returns a Map of file identifier and s3 object url
	 * @param files An array of file objects
	 * @returns {Map<string, string>} A map of file identifier and s3 object url
	 */
	public static async UploadFile(files: FileUpload[]): Promise<Map<string, string>> {
		const uploadPayload = new FormData();
		const meta = new Map();
		for(const file of files){
			uploadPayload.append(file.fileId, file.fileBinary);
			const {fileBinary, fileId, ...fileMeta} = file;
			meta.set(file.fileId, fileMeta);
		}
		uploadPayload.append("meta", JSON.stringify(Array.from(meta.entries())));
		const uploadResponse = await getAxiosInstance().post("/files", uploadPayload, {headers: { "Content-Type": "multipart/form-data" }});
		return new Map(uploadResponse.data);
	}

  // Booklinker
  public static async CreateBooklinkerLink(bookLink: string): Promise<CreateBooklinkerResponse> {
		const booklinkerLink = await getAxiosInstance().post("/booklinker", { bookLink });
    return booklinkerLink.data;
  }

  public static async getGoogleFonts({ search, category, count, page }: {
    search: string,
    category: string[],
    page: number,
    count: number
  }):
    Promise<{
      favoritedIds: string[],
      allFonts: IFontStore.FontItem[]
      totalCount: number
    }> {
    const bookResp = await getAxiosInstance().get(endpoints.fonts.get, {
      params: {
        search,
        category,
        page,
        count,
        source: "google"
      }
    });
    return bookResp.data;
  }

  public static async AddFontToFavorite(fontId: string): Promise<any> {
    const putBookResp = await getAxiosInstance().post(endpoints.fonts.favourite.post, {
      fontId
    });
    return putBookResp.data;
  }

  public static async RemoveFontFromFavorite(fontId: string): Promise<any> {
    const putBookResp = await getAxiosInstance().delete(endpoints.fonts.favourite.delete(fontId));
    return putBookResp.data;
  }

  public static async getFavoritedFonts(): Promise<IFontStore.FontItem[]> {
    const bookResp = await getAxiosInstance().get(endpoints.fonts.favourite.get);
    console.log("book resp: ", bookResp.data);
    
    return bookResp.data;
  }

  public static async GetUserFavouriteFonts(): Promise<IFontStore.FontItem[][]> {
    const response = await getAxiosInstance().get("fonts/user-favourites");
    return response.data;
  }

  public static async AddCollaborator(payload: ICollabStore.AddCollaboratorPayload): Promise<AddCollaboratorResponse> {
    const response = await getAxiosInstance().post(endpoints.collaboration.post, payload);
    return response.data;
  }

  public static async AddPendingCollaborator(payload: ICollabStore.AddCollaboratorPayload): Promise<AddPendingCollaboratorResponse> {
    const response = await getAxiosInstance().post(endpoints.collaboration.pending_post, payload);
    return response.data;
  }
  
  public static async UpdateCollaboratorType(collaboration_id: string): Promise<any> {
    const response = await getAxiosInstance().put(endpoints.collaboration.put_type(collaboration_id));
    return response.data;
  }

  public static async RemoveCollaborator(collaborator_id: string): Promise<any> {
    const response = await getAxiosInstance().delete(endpoints.collaboration.delete_user(collaborator_id));
    return response.data;
  }

  public static async RemoveCollaboration(collaboration_id: string): Promise<any> {
    const response = await getAxiosInstance().delete(endpoints.collaboration.delete(collaboration_id));
    return response.data;
  }

  public static async GetCollaborators(): Promise<{
    collaborators: ICollabStore.AuthorMetaMap
  }> {
    const response = await getAxiosInstance().get(endpoints.collaboration.get_collaborators);
    return response.data;
  }

  public static async GetBookCollaborators(book_id: string): Promise<{
    bookCollaborators: ICollabStore.BookCollaborator[]}
    >{
    const response = await getAxiosInstance().get(endpoints.collaboration.get_bookcollaborators(book_id));
    return response.data;
  }

  public static async GetCollaborations(): Promise<{
    collaborators: ICollabStore.AuthorMetaMap,
    collaborations: ICollabStore.BookCollaboration[],
  }> {
    const response = await getAxiosInstance().get(endpoints.collaboration.get);
    return response.data;
  }

  public static async GetCollaborated(): Promise<{
    books: RemoteBook[];
    collaborations: ICollabStore.BookCollaboration[],
    themes: IThemeStore.ThemeResponse[],
    authors: ICollabStore.AuthorMetaMap;
  }> {
    const booksResp = await getAxiosInstance().get(endpoints.collaboration.get_collaborated);
    return booksResp.data;
  }

  public static async GetCollaboratedBook(bookId:string): Promise<{
    book: RemoteBook;
    collaborations: ICollabStore.BookCollaboration,
    theme: IThemeStore.ThemeResponse,
    authors: ICollabStore.AuthorMetaMap;
  }> {
    const booksResp = await getAxiosInstance().get(endpoints.collaboration.get_collaboratedBook(bookId));
    return booksResp.data;
  }

  public static async GetNotifications(page: number, count: number): Promise<GetNotificationResponse> {
    const response = await getAxiosInstance().get(endpoints.notification.get(page, count));
    return response.data;
  }


  public static async GetNotification(id: string): Promise<INotificationStore.Notification | null> {
    const response = await getAxiosInstance().get(endpoints.notification.get_one(id));
    return response.data;
  }

  public static async GetUnreadNotificationsCount(): Promise<{ count: number }> {
    const response = await getAxiosInstance().get(endpoints.notification.get_unread_count);
    return response.data;
  }

  public static async MakeNotificationRead(notification_id: string): Promise<BaseResponse>{
    const response = await getAxiosInstance().put(endpoints.notification.post_read(notification_id));
    return response.data;
  }

  public static async DismissNotification(notification_id: string): Promise<BaseResponse>{
    const response = await getAxiosInstance().put(endpoints.notification.post_dismiss(notification_id));
    return response.data;
  }

  public static async DismissAllNotifications(): Promise<BaseResponse>{
    const response = await getAxiosInstance().put(endpoints.notification.post_dismiss_all);
    return response.data;
  }
}

