import { BaseEditor, NodeEntry, Path, Editor, Transforms, Node } from "slate";
import {
  isElement,
  getParentNode,
  TRange,
  liftNodes,
} from "@udecode/plate-core";

import {
  MyOrnamentalBreakElement,
  MySceneElement,
} from "../../../config/typescript";
import { ELEMENT_SCENE } from "../createScenePlugin";
import { ELEMENT_ORNAMENTAL_BREAK } from "../createOrnamentalBreakPlugin";

import {
  findAllNodesInDocByType,
  getAdjoiningSiblings,
} from "../../../../../utils/slate";
import { SceneUtils } from "../../../../../utils/scene/sceneServices";
import { ELEMENT_PARAGRAPH, setNodes } from "@udecode/plate";

export function withScenes(
  editor,
  currentScene: IChapterStore.CurrentScene | null,
  handleScenes: () => void
) {
  const plateEditor = editor as BaseEditor;
  const { deleteFragment, normalizeNode, deleteBackward, deleteForward } =
    plateEditor;

  /**
   * Overwrite editor delete operations when a user is inside of a scene to prevent
   * accidential deletions of content in other scenes
   * */
  plateEditor.deleteBackward = (unit) => {
    if (!currentScene) {
      deleteBackward(unit);
      return;
    }
    const currentSceneIndex = currentScene?.scene.sceneIndex;
    const selection = plateEditor.selection;
    if (!selection?.anchor) return;
    const { anchor } = selection;
    const anchorAncestorScene = Editor.above<MySceneElement>(plateEditor, {
      at: anchor,
      match: (node) =>
        isElement(node) &&
        node.type === ELEMENT_SCENE &&
        (node as MySceneElement).sceneIndex === currentSceneIndex,
    });
    const isAnchorInScene = !!anchorAncestorScene;
    if (isAnchorInScene) {
      deleteBackward(unit);
      return;
    }
  };

  plateEditor.deleteForward = (unit) => {
    if (!currentScene) {
      deleteForward(unit);
      return;
    }
    const currentSceneIndex = currentScene?.scene.sceneIndex;
    const selection = plateEditor.selection;
    if (!selection?.anchor) return;
    const { anchor } = selection;
    const anchorAncestorScene = Editor.above<MySceneElement>(plateEditor, {
      at: anchor,
      match: (node) =>
        isElement(node) &&
        node.type === ELEMENT_SCENE &&
        (node as MySceneElement).sceneIndex === currentSceneIndex,
    });
    const isAnchorInScene = !!anchorAncestorScene;
    if (isAnchorInScene) {
      deleteForward(unit);
      return;
    }
  };

  plateEditor.deleteFragment = (direction) => {
    if (!currentScene) {
      deleteFragment(direction);
      return;
    }
    const currentSceneIndex = currentScene?.scene.sceneIndex;
    const selection = plateEditor.selection;
    if (!selection?.anchor || !selection.focus) return;
    const { anchor, focus } = selection;
    const anchorAncestorScene = Editor.above<MySceneElement>(plateEditor, {
      at: anchor,
      match: (node) =>
        isElement(node) &&
        node.type === ELEMENT_SCENE &&
        (node as MySceneElement).sceneIndex === currentSceneIndex,
    });
    const focusAncestorScene = Editor.above<MySceneElement>(plateEditor, {
      at: focus,
      match: (node) =>
        isElement(node) &&
        node.type === ELEMENT_SCENE &&
        (node as MySceneElement).sceneIndex === currentSceneIndex,
    });
    const isAnchorInScene = !!anchorAncestorScene;
    const isFocusInScene = !!focusAncestorScene;
    /** if both anchor and focus of the current selection is outside of the active scene, do nothing */
    if (!isAnchorInScene && !isFocusInScene) {
      return;
    }
    /** if both anchor and focus of the current selection is within the active scene, delete the selection without modifying  */
    if (isAnchorInScene && isFocusInScene) {
      Transforms.delete(plateEditor, { at: selection });
      return;
    }
    if (isAnchorInScene || isFocusInScene) {
      /** check if anchor point is before focus (can select fragment top to bottom or bottom to top) */
      const isAnchorBeforeFocus = Path.isBefore(anchor.path, focus.path);
      /** if only anchor is within the current scene */
      if (isAnchorInScene) {
        const sceneStartPoint = Editor.start(
          plateEditor,
          anchorAncestorScene[1]
        );
        const sceneEndPoint = Editor.end(plateEditor, anchorAncestorScene[1]);
        if (isAnchorBeforeFocus) {
          Transforms.delete(plateEditor, {
            at: { anchor, focus: sceneEndPoint },
          });
          return;
        } else {
          Transforms.delete(plateEditor, {
            at: { anchor: sceneStartPoint, focus: anchor },
          });
          return;
        }
      }
      /** if only focus is within the current scene */
      if (isFocusInScene) {
        const sceneStartPoint = Editor.start(
          plateEditor,
          focusAncestorScene[1]
        );
        const sceneEndPoint = Editor.end(plateEditor, focusAncestorScene[1]);
        if (isAnchorBeforeFocus) {
          Transforms.delete(plateEditor, {
            at: { anchor: sceneStartPoint, focus },
          });
          return;
        } else {
          Transforms.delete(plateEditor, {
            at: { anchor: focus, focus: sceneEndPoint },
          });
          return;
        }
      }
    }
  };

  /**
   * Normalizer logic
   */
  plateEditor.normalizeNode = (entry: NodeEntry) => {
    const [node, path] = entry;
    
    // Retrieve all ornamental break nodes in doc
    const ornamentalBreakNodes =
      findAllNodesInDocByType<MyOrnamentalBreakElement>(
        editor,
        ELEMENT_ORNAMENTAL_BREAK
      );

    // Iterate through each formatted ornamental break to ensure proper scene wrapping.
    ornamentalBreakNodes.forEach((obNodeEntry) => {
      const isParentAScene =
        getParentNode(editor, obNodeEntry[1])?.[0].type === ELEMENT_SCENE;
      const adjoiningSiblings = getAdjoiningSiblings(editor, obNodeEntry[1]);

      // Skip processing if both previous and next siblings are already scene nodes.
      if (
        isElement(adjoiningSiblings.previous?.[0]) &&
        adjoiningSiblings.previous?.[0].type === ELEMENT_SCENE &&
        isElement(adjoiningSiblings.next?.[0]) &&
        adjoiningSiblings.next?.[0].type === ELEMENT_SCENE
      )
        return;

      if (!isParentAScene) {
        try {
          // Setting the topRange from the start of the document until the top of the ornamental-break
          const topRange: TRange = {
            anchor: Editor.start(editor, []),
            focus: Editor.point(editor, obNodeEntry[1], {
              edge: "start",
            }),
          };

          // Setting the bottomRange from the end of the ornamental-break to the end of the document
          const bottomRange: TRange = {
            anchor: Editor.point(editor, obNodeEntry[1], { edge: "end" }),
            focus: Editor.end(editor, []),
          };

          // Wrap nodes at the topRange and bottomRange with a scene element
          // The combined behavior of the liftNode and {split:true} of wrapNodes will break the scenes into separate nodes
          Transforms.wrapNodes(
            editor,
            { type: ELEMENT_SCENE, children: [] } as any,
            { at: bottomRange, split: true, mode: "all" } as any
          );
          Transforms.wrapNodes(
            editor,
            { type: ELEMENT_SCENE, children: [] } as any,
            { at: topRange, split: true, mode: "all" } as any
          );
        } catch (error) {
          console.log(error);
        }
      }
    });

    // If the current node is a scene, lift its children if they are scenes or ornamental breaks.
    if (isElement(node) && node.type === ELEMENT_SCENE) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (
          isElement(child) &&
          [ELEMENT_SCENE, ELEMENT_ORNAMENTAL_BREAK].includes(child.type)
        ) {
          liftNodes(editor, {
            at: childPath,
          });
          return;
        }
      }
    }

    // Traverse and lift child nodes for pre-existing scene nodes to ensure correct hierarchy.
    const preSceneNodes = findAllNodesInDocByType<MySceneElement>(
      editor,
      ELEMENT_SCENE
    );
    for (const [index, sceneNode] of preSceneNodes.entries()) {
      for (const [child, childPath] of Node.children(editor, sceneNode[1])) {
        if (
          isElement(child) &&
          [ELEMENT_SCENE, ELEMENT_ORNAMENTAL_BREAK].includes(child.type)
        ) {
          liftNodes(editor, {
            at: childPath,
          });
          return;
        }
      }
    }

    // Update the sceneIndex(s)
    const sceneNodes = findAllNodesInDocByType<MySceneElement>(
      editor,
      ELEMENT_SCENE
    );
    for (const [index, sceneNode] of sceneNodes.entries()) {
      Transforms.setNodes(
        editor,
        {
          ...sceneNode[0],
          sceneIndex: index,
        } as any,
        { at: sceneNode[1] }
      );
    }

    // Update scene information in the application state upon ornamental break change
    const isOrnamentalBreakNode =
      isElement(node) && node.type === ELEMENT_ORNAMENTAL_BREAK;
    if (isOrnamentalBreakNode) {
      handleScenes();
      SceneUtils.updateSceneTitles(editor);
    }
    normalizeNode(entry);
  };
  return editor;
}
