import { Path, Transforms, BaseEditor } from "slate";
import {
  unsetNodes,
  setNodes,
  getNodeString,
  Value,
  PlateEditor,
} from "@udecode/plate";
import { v4 as uuidv4 } from "uuid";
import { TCommentText } from "../plugins/comments";
import { TTrackChange, TrackChangeReply } from "../plugins/track-changes";

export const setNodeTrackChanges = (
  editor: PlateEditor,
  replies: Record<string, TrackChangeReply>,
  path: Path
): void => {
  unsetNodes<TCommentText>(editor, ["replies"], {
    at: path,
  });
  setNodes(editor, { replies }, { at: path });
};

export const approveTrackChange = (
  editor: PlateEditor,
  trackChanges: TTrackChange[]
): void => {
  const insertsAndUpdates: TTrackChange[] = [];
  const deletes: TTrackChange[] = [];

  trackChanges.forEach((trackChange) => {
    const { operation } = trackChange.tc.trackChanges;

    if (operation === "insert" || operation === "update" || operation === "format") {
      insertsAndUpdates.push(trackChange);
    } else if (operation === "delete") {
      deletes.push(trackChange);
    }
  });

  // Process inserts and updates
  insertsAndUpdates.forEach((trackChange) => {
    unsetNodes(editor, ["trackChanges"], { at: trackChange.path });
  });

  // Process deletes
  const reversedDeletes = [...deletes].reverse();
  reversedDeletes.forEach((trackChange) => {
    Transforms.delete(editor as BaseEditor, {
      at: trackChange.path,
    });
  });
};

function removeFormattingFromPluginProperties(trackChange) {

  let pluginProperties = [
      "underline", 
      "bold", 
      "italic",
      "monospace",
      "smallcaps",
      "sansserif",
      "subscript",
      "superscript",
      "strikethrough",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
  ];

  // Filter out properties that exist in trackChange.tc.trackChanges.formatting
  pluginProperties = pluginProperties.filter(
      (property) => !Object.prototype.hasOwnProperty.call(trackChange.tc.trackChanges.formatting, property)
  );

  return pluginProperties;
}

export const declineTrackChange = (
  editor: PlateEditor,
  trackChanges: TTrackChange[]
): void => {
  const insertsAndUpdates: TTrackChange[] = [];
  const deletes: TTrackChange[] = [];
  const format: TTrackChange[] = [];

  trackChanges.forEach(trackChange => {
    const { operation } = trackChange.tc.trackChanges;

    if (operation === "insert" || operation === "update") {
      insertsAndUpdates.push(trackChange);
    } else if (operation === "delete") {
      deletes.push(trackChange);
    } else if (operation === "format") {
      format.push(trackChange);
    } 
  });

  // Process deletes
  deletes.forEach((trackChange) => {
    unsetNodes(editor, ["trackChanges"], { at: trackChange.path });
  });

  // Process inserts and updates
  const reversedInsertsAndUpdates = [...insertsAndUpdates].reverse();
  reversedInsertsAndUpdates.forEach((trackChange) => {
    Transforms.delete(editor as BaseEditor, { at: trackChange.path });
  });

  // Process formatting
    
  format.forEach(trackChange => {
    const updatedPluginProperties = removeFormattingFromPluginProperties(trackChange);
    //set the original formatting properties and unSet the updated formattings 
    setNodes(
      editor,
      trackChange.tc.trackChanges.formatting,
      { at: trackChange.path }
    );
    unsetNodes(editor, ["trackChanges", ...updatedPluginProperties], { at: trackChange.path });
    
  });
};

export const submitReplyTrackChange = (
  editor: PlateEditor,
  user: IAuthStore.ProfileProps | null,
  replyValue: Value,
  trackChange: TTrackChange
): void => {
  const replyId = uuidv4();
  const newReply: TrackChangeReply = {
    value: getNodeString(replyValue[0]),
    createdAt: Date.now(),
    userId: user?._id as string,
    replyId: replyId,
  };

  const reply: Record<string, TrackChangeReply> = {
    [newReply.replyId]: newReply,
  };

  const replies = Object.assign({}, trackChange.tc.replies, reply);
  setNodes(
    editor,
    {
      replies,
    },
    { at: trackChange.path }
  );
};

export const updateTrackChangeReply = (
  editor: PlateEditor,
  trackChangeNode: TTrackChange,
  updatedValue: string,
  reply: TrackChangeReply
): void => {
  const updatedReply: TrackChangeReply = {
    ...reply,
    value: updatedValue,
  };
  const replies = trackChangeNode.tc.replies;
  if (replies) {
    replies[reply.replyId] = {
      ...updatedReply,
    };
    setNodeTrackChanges(editor, replies, trackChangeNode.path);
  }
};

export const deleteTrackChangeReply = (
  editor: PlateEditor,
  trackChange: TTrackChange,
  reply: TrackChangeReply
): void => {
  const replies = trackChange.tc.replies;
  if (replies) {
    delete replies[reply.replyId];
    setNodeTrackChanges(editor, replies, trackChange.path);
  }
};
